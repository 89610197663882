import React, { useState } from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import _ from "lodash";
import { KeywordCard } from "./keywordCard";
import {
  tokenizeText,
  tokensToKeywords,
  formatTextWithTooltips,
  findKeywordEntry,
} from "./utils";
import { KEYWORDS } from "./keywords";
import { SubscribeForm } from "../common/subscribeForm";
import ReactHintFactory from "react-hint";
import "react-hint/css/index.css";

const ReactHint = ReactHintFactory(React);

const Output = styled.div`
  width: 100%;
  min-width: 500px;
  background-color: #ebebeb;
  padding: 0.5rem;

  .kwti {
    color: #4287f5;
    border-bottom: 1px solid blue;
    cursor: pointer;
  }
`;

const CardContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;
const CardWrap = styled.div`
  padding: 0.5rem 1rem;
  max-width: 500px !important;
  min-width: 320px;
  width: 45%;
  margin: 1rem;

  @media only screen and (max-width: 750px) {
    width: 90%;
  }
`;

const PBlock = styled.div`
  p {
    margin-bottom: 1rem;
  }
`;

const KeywordTool = () => {
  // raw text input
  const [text, setText] = useState(null);
  // a map of keywords from the raw text
  const [mappedKeywords, setMappedKeywords] = useState({});
  // a set of annotated text with spans that have info tooltips
  const [textOutput, setTextOutput] = useState(null);

  const textAreaOnChange = (e) => {
    const { value } = e.target;
    const { formattedText, tokens } = formatTextWithTooltips(value);
    console.log("Formatted text", formattedText);
    const keywords = tokensToKeywords(tokens);

    setText(value);
    setMappedKeywords(keywords);
    setTextOutput(formattedText);
  };

  return (
    <div className="container">
      <div className="columns">
        <div className="column is-4">
          <h3 className="title">How does this work?</h3>
          <PBlock>
            <p>
              This tool will find and highlight keywords in a Job Description or
              Resume. To use it copy the text of the JD or Resume and paste it
              in the textbox, the tool will then automatically find the
              technical terms related to software development and highlight
              them. You can hover over highlighted terms to get more
              information.
            </p>
            <p>
              Note that the tool currently only covers some technical terms
              related to software development and I am working to expand the
              library of terms.
            </p>
            <p>
              This tool is a work in progress and I continue to add terms as I
              see them pop up in JDs. Missing something? {' '}
              <Link to="/contact">Contact me and let me know!</Link>
            </p>
          </PBlock>
        </div>
        <div className="column is-8">
          <h3 className="title">Paste Job Description or Resume Text Below</h3>
          <textarea
            onChange={textAreaOnChange}
            className="textarea"
            placeholder="Paste as much text as you want here"
            rows="5"
          />
          <hr />
          <h3 className="title">Output:</h3>
          <Output>
            {textOutput ? (
              <div dangerouslySetInnerHTML={{ __html: textOutput }} />
            ) : (
              <div>
                <p>
                  There is no output to display. Please paste the text of a Job
                  Description or Resume above.
                </p>
              </div>
            )}
          </Output>
        </div>
      </div>
      <div className="columns">
        <div className="column is-12">
          <h2 className="title">
            Found Keywords List {_.toArray(mappedKeywords).length || 0}/
            {KEYWORDS.length}
          </h2>
          {_.isEmpty(mappedKeywords) ? (
            <div>
              {!_.isEmpty(textOutput) ? (
                <h3 className="has-text-danger">
                  No keywords found. Note that the library of terms only covers
                  topics related to software development right now.
                </h3>
              ) : null}
            </div>
          ) : (
            <CardContainer>
              {_.toArray(mappedKeywords).map((keywordEntry, index) => {
                return (
                  <CardWrap key={index}>
                    <KeywordCard keywordEntry={keywordEntry} />
                  </CardWrap>
                );
              })}
            </CardContainer>
          )}
        </div>
      </div>
      <div className="columns">
        <div className="column is-6">
          <hr />
          <h3 className="title">Have some feedback?</h3>
          <p>
            Did you use this tool and like it? Hate it? Want another feature? If
            you have any feedback for me please feel free to get in touch. I
            love to hear from people that use things that I make.
          </p>
          <br />
          <p>
            <Link className="button is-primary" to="/contact">
              Contact Me
            </Link>
          </p>
        </div>
        <div className="column is-5">
          <hr />
          <h3 className="title">A Note About Data</h3>
          <p>
            I don't store or send anything you paste here. The only software I
            use that is sending data anywhere is Google Analytics, which I just
            use to track page views.
          </p>
          <br />
        </div>
      </div>
      <div className="columns">
        <div className="column is-12">
          <article className="message is-primary">
            <div className="message-header">
              <p>Sign up for updates</p>
            </div>
            <div className="message-body">
              <p className="is-size-5" style={{ marginBottom: "1rem" }}>
                By the way, I make courses for new technical recruiters. If you
                organization needs something like this{" "}
                <Link to="/courses">please check them out!</Link>
              </p>
              <p className="is-size-5">
                I write blogs pretty frequently about technical topics for
                recruiters, so if you are interested in that kind of content
                sign up for updates.
              </p>

              <SubscribeForm />
            </div>
          </article>
        </div>
      </div>
      <ReactHint
        autoPosition
        events
        attribute="data-customrh"
        onRenderContent={(target, content) => {
          // console.log("React hint", target, content);
          const { name } = target.dataset;
          const keywordMatched = findKeywordEntry(name);
          if (!keywordMatched) {
            return (
              <div className="react-hint__content">
                No details for this keyword
              </div>
            );
          }
          return (
            <div
              className="react-hint__content"
              style={{ maxWidth: "350px", minWidth: "200px" }}
            >
              <h4 className="is-size-4 has-text-white-ter">
                {keywordMatched.name}
              </h4>
              {keywordMatched.info}
            </div>
          );
        }}
      />
    </div>
  );
};

export default KeywordTool;

import React from 'react'
import KeywordTool from './KeywordTool'

const JobKeywordExplorerPage = ({ title, description }) => {
  return (
    <div>
      <section className='hero is-primary is-bold'>
        <div className='hero-body'>
          <div className='container'>
            <div className='columns'>
              <div className='column is-10 is-offset-1'>
                <div className='section'>
                  <h1 className='title has-text-white is-size-1'>{title}</h1>
                  <h2 className='description has-text-white is-size-3'>
                    {description}
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='section section--gradient'>
        <div className='container'>
          <div className='columns'>
            <div className='column is-10 is-offset-1'>
              <KeywordTool />
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default JobKeywordExplorerPage
